import { logoSrc } from '../../images';
import React from 'react';
import ProSubscription from '../../components/ProSubscription';
import { Table } from '../../components/Table';
import useSWR from 'swr';
import axios from 'axios';

const AppView = () => {
	const { data: plans } = useSWR('/api/plans', async (): Promise<any[]> => {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_API_URL}/communities/plans`
			);
			return response.data.items;
		} catch (error) {
			console.error('Error:', error);
		}
		return [];
	});
	return (
		<main className="flex flex-col justify-between min-h-screen bg-neutral-800 md:px-16 px-2">
			<header className="p-4 flex justify-center items-center">
				<a className="w-64 h-14" href="https://www.collab.land">
					<img src={logoSrc} alt="Collab.Land" />
				</a>
			</header>
			<section
				className={
					'flex gap-6 flex-col justify-center align-middle items-center'
				}
			>
				<h1 className={'text-5xl font-extrabold'}>
					Discord Subscription Tiers
				</h1>
				<h1 className={'text-3xl font-extrabold'}>
					See Telegram Subscription Tiers{' '}
					<span
						className={'underline cursor-pointer'}
						onClick={() => {
							window.location.href = '#TelegramSection';
						}}
					>
						below
					</span>
				</h1>
				<p className={'px-6'}>
					We're proud to introduce four custom tiers of Collab.Land service
					tailored to the needs of our Discord communities. Each tier offers
					unique features - ones you've all been requesting - such as PRO
					miniapps, on-demand balance checks, advanced bot and messaging
					customization, and more.
				</p>
				<p className={'p-6'}>
					All tiers include our robust network of blockchains (34 L1s + L2s and
					counting!), 28 wallets, Delegate and OpenSea verification, and the
					most token types of any community-building tool.{' '}
					<span
						className={'underline cursor-pointer'}
						onClick={() => {
							window.location.href = '#SupportedChainsAndNetworks';
						}}
					>
						Scroll to see the full list!
					</span>
				</p>
				<ProSubscription
					plans={
						plans?.filter(
							plans => !plans.hide && plans.pk.startsWith('BOT#PLAN')
						) ?? []
					}
				/>
				<Table />
				<hr className={' w-full border-neutral-500'}></hr>
				<h1 id={'TelegramSection'} className={'text-5xl font-extrabold'}>
					Telegram Subscription Tiers
				</h1>
				<p className={'p-6'}>
					Introducing a new level of Collab.Land service for Telegram groups,
					starting with the premium tier. All tiers include all supported
					blockchain, wallets (including Safe), Delegate and OpenSea
					verification, and the most token types of any community tool. See the
					full list{' '}
					<span
						className={'underline cursor-pointer'}
						onClick={() => {
							window.location.href = '#SupportedChainsAndNetworks';
						}}
					>
						here.
					</span>
				</p>
				<ProSubscription
					plans={
						plans?.filter(
							plans => !plans.hide && plans.pk.startsWith('TEL#BOT#PLAN')
						) ?? []
					}
				/>
				<hr className={' w-full border-neutral-500'}></hr>
				<div
					className={'w-fit flex flex-col justify-center align-middle gap-6'}
				>
					<h1
						id="SupportedChainsAndNetworks"
						className={'text-left text-5xl font-extrabold'}
					>
						Supported Chains and Networks
					</h1>
					<SupportedChainsAndNetworks />
					<h1 className={'text-left w-fit text-5xl font-extrabold'}>
						Supported Wallets and Verifications
					</h1>
					<SupportedWalletsAndVerifications />
				</div>
			</section>
			<footer className="p-4 flex flex-row items-center justify-between">
				<div className="flex justify-between items-center gap-4 ml-auto">
					{/*<img src={logoSrc} alt="Collab.Land"/>*/}
					<div className="flex gap-4 md:flex-row flex-col">
						<a
							className="text-neutral-500 font-semibold"
							href="https://www.collab.land/privacy-policy "
						>
							Privacy Policy
						</a>
						<a
							className="text-neutral-500 font-semibold"
							href="https://www.collab.land/terms-of-service "
						>
							Terms of Service
						</a>
						<a
							className="text-neutral-500 font-semibold"
							href="https://www.collab.land"
						>
							©2023 Collab.Land
						</a>
					</div>
				</div>
			</footer>
		</main>
	);
};

const SupportedChainsAndNetworks = () => {
	let items = [
		'Amoy (Polygon testnet)',
		'Arbitrum Nova',
		'Arbitrum One',
		'Astar zkEVM',
		'Avalanche',
		'BASE',
		'Bitlayer',
		'Blast',
		'Bitcoin',
		'BSC',
		'Celo',
		'DOS Chain',
		'Eluvio',
		'Ethereum Mainnet',
		'Flow',
		'Friend.Tech',
		'Gitcoin Passport',
		'Gnosis',
		'Immutable X',
		'Kusama',
		'Linea',
		'Loopring',
		'Moonbeam',
		'NEAR',
		'Nifty',
		'Optimism',
		'Palm',
		'Polkadot',
		'Polygon',
		'Q Chain',
		'Ronin',
		'SEI',
		'Sepolia (Ethereum testnet)',
		'Shibarium',
		'Solana',
		'Tezos',
		'XRPL'
	];

	let chunks = [];
	while (items.length) chunks.push(items.splice(0, 10));

	return (
		<div className="flex flex-wrap justify-center px-2">
			{chunks.map((chunk, index) => (
				<div key={index} className="w-48">
					<ul className="list-disc">
						{chunk.map((item, key) => (
							<li key={key}>{item}</li>
						))}
					</ul>
					{chunk.length < 10 && (
						<>
							{/* <br /> */}
							{/* <p className={'italic'}>Coming Soon</p> */}
							<ul className="list-disc">
								<li>and more coming soon!</li>
							</ul>
						</>
					)}
				</div>
			))}
		</div>
	);
};

const SupportedWalletsAndVerifications = () => {
	let items = [
		'Bitski',
		'Blocto',
		'Coinbase Wallet',
		'Dapper',
		'Delegate',
		'Eluvio',
		'Fortmatic',
		'Friend.Tech',
		'ImmutableX',
		'Leather',
		'Ledger',
		'Loopring',
		'MEWconnect',
		'MetaMask',
		'Near',
		'Nifty Gateway',
		'OpenSea',
		'Opolis',
		'Phantom',
		'Phantom EVM',
		'Ronin',
		'Roll',
		'Safe',
		'Talisman',
		'Temple (Tezos)',
		'TokenProof',
		'Trezor',
		'Venly',
		'WalletConnect(V2)',
		'Xumm',
		'Xverse'
	];

	let chunks = [];
	while (items.length) chunks.push(items.splice(0, 8));

	return (
		<div className="flex flex-wrap justify-center px-2">
			{chunks.map((chunk, index) => (
				<div key={index} className="w-48">
					<ul className="list-disc">
						{chunk.map((item, key) => (
							<li key={key}>{item}</li>
						))}
					</ul>
				</div>
			))}
		</div>
	);
};
export default AppView;
